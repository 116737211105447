export const register = (state = {}, action) => {
  switch (action.type) {
    case "SIGNUP_WITH_EMAIL": {
      return {
        ...state,
        values: action.payload,
        errors: action.errors
      }
    }
    default: {
      return state
    }
  }
}
