// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import auth from "./reducers/auth/"
import userReducers from "./reducers/users/index"
import propertiesReducers from "./reducers/properties/index"
import managementReducers from "./reducers/management/index"
import automationReducers from "./reducers/automation/index"
import statisticsReducers from "./reducers/statistics/index"
import chat from '@src/views/management/chat/store'

// import calendar from '@src/views/properties/calendar/store'

const rootReducer = { chat, navbar, layout, auth, user: userReducers, properties: propertiesReducers, management: managementReducers, automation: automationReducers, statistics: statisticsReducers}

export default rootReducer 