import { combineReducers } from "redux"
import { propertiesReducer } from "./propertiesReducer"
import { calendarReducer } from "./calendarReducer"

const propertiesReducers = combineReducers({
  propertiesReducer,
  calendarReducer
})

export default propertiesReducers
