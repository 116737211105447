export const statisticsReducer = (state = {}, action) => {
    switch (action.type) {
      case "STATISTICS_CONSUMPTION": {
        return {
          ...state,
          values: 'statistics_consumption',
          consumption: action.payload
        }
      }
      case "STATISTICS_CONSUMPTION_ERROR": {
        return {
          ...state,
          status: 'statistics_consumption_error',
          errors: action.errors
        }
      }
      case "STATISTICS_BOOKINGS": {
        return {
          ...state,
          values: 'statistics_booking',
          bookings: action.payload
        }
      }
      case "STATISTICS_BOOKINGS_ERROR": {
        return {
          ...state,
          status: 'statistics_booking_error',
          errors: action.errors
        }
      }
      case "STATISTICS_OCCUPANCY": {
        return {
          ...state,
          values: 'statistics_occupancy',
          occupancy: action.payload
        }
      }
      case "STATISTICS_OCCUPANCY_ERROR": {
        return {
          ...state,
          status: 'statistics_occupancy_error',
          errors: action.errors
        }
      }
      default: {
        return state
      }
    }
  }
  export default statisticsReducer