export const assistantsReducer = (state = {}, action) => {
  switch (action.type) {
    case "MANAGEMENT_ASSISTANT_INIT": {
      return {
        ...state,
        values: null,
        errors: null
      }
    }
    case "MANAGEMENT_ASSISTANT_ADDED": {
      return {
        ...state,
        values: 'assistant_added'
      }
    }
    case "MANAGEMENT_ASSISTANT_ADDED_ERROR": {
      return {
        ...state,
        errors: action.errors,
        values: 'assistant_not_added'
      }
    }
    case "MANAGEMENT_ASSISTANT": {
      return {
        ...state,
        assistants: action.payload,
        errors: action.errors
      }
    }
    case "MANAGEMENT_ASSISTANT_DELETED": {
      return {
        ...state,
        values: 'assistant_deleted'
      }
    }
    case "MANAGEMENT_ASSISTANT_DELETED_ERROR": {
      return {
        ...state,
        errors: action.errors,
        values: 'assistant_not_deleted'
      }
    }
    default: {
      return state
    }
  }
}
export default assistantsReducer