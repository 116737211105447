export const jobsReducer = (state = {}, action) => {
  switch (action.type) {
    case "MANAGEMENT_JOB_INIT": {
      if (action.keepSelected) {
        return {
          ...state,
          values: '',
          errors: ''
        }
      } else {
        return {
          ...state,
          values: '',
          errors: '',
          selectedJob: {}
        }
      }

    }
    case "MANAGEMENT_JOB_SELECT": {
      return {
        ...state,
        selectedJob: action.selectedJob
      }
    }
    case "MANAGEMENT_JOB_ADDED": {
      return {
        ...state,
        values: 'job_added'
      }
    }
    case "MANAGEMENT_JOB_ADDED_ERROR": {
      return {
        ...state,
        errors: action.errors
      }
    }
    case "MANAGEMENT_JOB_UPDATED": {
      return {
        ...state,
        values: 'job_updated'
      }
    }
    case "MANAGEMENT_JOB_STATE_UPDATED": {
      return {
        ...state,
        values: 'job_state_updated'
      }
    }
    case "MANAGEMENT_JOB_UPDATED_ERROR": {
      return {
        ...state,
        errors: action.errors
      }
    }
    case "MANAGEMENT_JOB_DELETED": {
      return {
        ...state,
        values: 'job_deleted'
      }
    }
    case "MANAGEMENT_JOB_DELETED_ERROR": {
      return {
        ...state,
        errors: action.errors
      }
    }
    case "MANAGEMENT_JOB": {
      return {
        ...state,
        jobs: action.payload,
        values: 'jobs_fetched'
      }
    }
    case "MANAGEMENT_JOB_ERROR": {
      return {
        ...state,
        errors: action.errors,
        values: 'jobs_fetched_error'
      }
    }
    default: {
      return {
        ...state,
        selectedJob: {},
        values: 'init'
      }
    }
  }
}
export default jobsReducer