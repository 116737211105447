export const propertiesReducer = (state = {}, action) => {
    switch (action.type) {
      case "USER_PROPERTIES": {
        return {
          ...state,
          finished: action.finished,
          values: action.payload,
          errors: action.errors
        }
      }
      case "PROPERTIES_INIT": {
        return {
          ...state,
          values: '',
          errors: ''
        }
      }
      case "PROPERTIES_ADDED": {
        return {
          ...state,
          values: 'properties_added'
        }
      }
      case "PROPERTIES_ADDED_ERROR": {
        return {
          ...state,
          values: 'properties_added_error',
          errors: action.errors
        }
      }
      case "PROPERTIES_UPDATE": {
        return {
          ...state,
          values: 'properties_updated'
        }
      }
      case "PROPERTIES_UPDATE_ERROR": {
        return {
          ...state,
          values: 'properties_updated_error',
          errors: action.errors
        }
      }
      default: {
        return state
      }
    }
  }
  