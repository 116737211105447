export const accountReducers = (state = {}, action) => {
  switch (action.type) {
    case "USER_ACCOUNT": {
      // Set tagProperties for Select / Option
        const propertiesTag = []
        let propertySelected = null
      if (action.payload && action.payload.properties) {
        action.payload.properties.map((curr_property, i) => {
          propertiesTag.push({ value: curr_property.uuid, label: curr_property.title, property: curr_property, key: curr_property.uuid, hasConnectedKey: curr_property.hasConnectedKey })
          // Select first property
          if (i === 0 && propertySelected === null) {
            propertySelected = { value: curr_property.uuid, label: curr_property.title }
          }
        })
      }

      if(action.payload && typeof action.payload != 'string') {
        action.payload.propertiesTag = propertiesTag;
      }

      return {
        ...state,
        values: action.payload,
        status: 'user_account',
        datas: action.payload,
        propertiesTag,
        propertySelected,
        errors: action.errors
      }
    }
    case "USER_ACCOUNT_ERROR": {
      return {
        ...state,
        errors: action.errors
      }
    }
    default: {
      return state
    }
  }
}
