export const energyReducer = (state = {}, action) => {
    switch (action.type) {
      case "ENERGY_ENEDIS_CHECKED": {
        return {
          ...state,
          values: 'energy_enedis_checked',
          usage_point_ids: action.payload.addresses,
          token: action.payload.token.access_token,
          refreshToken: action.payload.token.refresh_token,
          expire: action.payload.token.expires_in
        }
      }
      case "ENERGY_ENEDIS_CHECKED_ERROR": {
        return {
          ...state,
          status: 'energy_enedis_checked_error',
          errors: action.errors/*,
          usage_point_ids: [23456789, 456789, 4567856789],
          token: '1234567',
          refreshToken: '1234567',
          expire: '1234567'*/
          //usage_point_ids: []
          /*
          *
          *{
    "addresses": [
        {
            "usage_point_id": "64975835695673",
            "address": {
                "error": "Precondition Failed",
                "error_description": "Préconditions envoyées par la requête non vérifiées"
            }
        },
        {
            "usage_point_id": "63695879465986",
            "address": {
                "error": "Precondition Failed",
                "error_description": "Préconditions envoyées par la requête non vérifiées"
            }
        },
        {
            "usage_point_id": "22315546958763",
            "address": {
                "error": "Precondition Failed",
                "error_description": "Préconditions envoyées par la requête non vérifiées"
            }
        }
    ],
    "token": {
        "access_token": "jqSDpc6i1XRYyMnZ0FayCRE2KRsQ8YVMBCFvG6Mij2AgeIWLLbaDMN",
        "refresh_token": "GR7kao1KipDtHtHd8u1NDS63BedMvouObM4M6M6qPrOb0o",
        "expires_in": 12600
    }
}
          * 
          */
        }
      }
      case "ENERGY_CONSUMPTION_TREATED": {
        return {
          ...state,
          status: 'energy_consumption_treated'
        }
      }
      case "ENERGY_CONSUMPTION_CHECKED": {
        return {
          ...state,
          status: 'energy_consumption_checked',
          datas: action.payload
        }
      }
      case "ENERGY_CONSUMPTION_CHECKED_ERROR": {
        return {
          ...state,
          status: 'energy_consumption_error',
          errors_consumption: action
        }
      }
      case "ENERGY_ENEDIS_ADDED": {
        return {
          ...state,
          status: 'energy_enedis_added'
        }
      }
      case "ENERGY_ENEDIS_ADDED_ERROR": {
        return {
          ...state,
          status: 'energy_enedis_error',
          errors_added: action.errors
        }
      }
      case "ENERGY_ENEDIS": {
        return {
          ...state,
          enedis: action.payload,
          errors: action.errors
        }
      }
      default: {
        return state
      }
    }
  }
  export default energyReducer