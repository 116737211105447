export const tutorialReducers = (state = {}, action) => {
    switch (action.type) {
      case "USER_TUTORIAL_PATCHED": {
        return {
          ...state,
          values: action.payload,
          errors: action.errors
        }
      }
      default: {
        return state
      }
    }
  }
  