export const calendarReducer = (state = {}, action) => {
  switch (action.type) {
    case "CALENDAR_INIT":
      return { ...state, status: '' }
    case "FETCH_EVENTS":
      return { ...state, events: action.events }
    case "FETCH_EVENTS_ERRORS":
      return { ...state, errors: action.errors }
    case "ADD_EVENT":
      state.events.push(action.event)
      return { ...state }

    case "REFRESH_CALENDARS":
      return { ...state, status: 'refresh_calendars' }
    case "REFRESH_CALENDARS_ERRORS":
      return { ...state, errors: action.errors }
    default:
      return state
  }
}

