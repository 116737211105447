//<ROOT>/shared/APIKit.js
import axios from 'axios'
const API_url = process.env.REACT_APP_API_BASE_URL

// Create axios client, pre-configured with baseURL
const APIKit = axios.create({
  baseURL: API_url,
  timeout: 60000,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('user_token')}`
  }
})

// Set JSON Web Token in Client to be included in all calls
export const setClientToken = token => {
  APIKit.interceptors.request.use(function(config) {
    config.headers.Authorization = `Bearer ${token}`
    return config
  })
}

if (localStorage.getItem('user_token')) {
  setClientToken(localStorage.getItem('user_token'))
}

export default APIKit